import { useRouter } from 'next/router';
import { useEffect } from 'react';
import {
  DraftDto,
  useApi,
  useApiCredentials,
  useApiQuery,
} from '../../shared/api';

type Props = {
  id: string;
  redirectIfNotFound?: string;
  redirectIfForbidden?: string;
  refetchInterval?: number;
  secret?: string;
};

export function useDraft(props: Props) {
  const {
    id,
    secret,
    redirectIfNotFound,
    redirectIfForbidden,
    refetchInterval,
  } = props;
  const api = useApi();
  const { hasToken } = useApiCredentials();

  const draftQuery = useApiQuery<DraftDto, unknown>(
    ['api/draft', { id }],
    async () => {
      const res = await api.blog.findDraftById(id, { secret });

      return res.data;
    },
    {
      enabled:
        secret || redirectIfForbidden || redirectIfNotFound ? true : hasToken,
      refetchInterval,
    },
  );

  const router = useRouter();

  useEffect(() => {
    if (
      draftQuery.isError &&
      draftQuery.error instanceof Response &&
      draftQuery.error.status === 404 &&
      redirectIfNotFound
    ) {
      router.push(redirectIfNotFound);
    } else if (
      draftQuery.isError &&
      draftQuery.error instanceof Response &&
      draftQuery.error.status === 403 &&
      redirectIfForbidden
    ) {
      router.push(redirectIfForbidden);
    }
  }, [
    draftQuery.isError,
    draftQuery.error,
    redirectIfNotFound,
    redirectIfForbidden,
    router,
  ]);

  return draftQuery;
}
