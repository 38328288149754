import styles from './styles.module.scss';
import { clsx } from 'clsx';
import { sanitizePostText } from '../../helpers';

type Props = {
  className?: string;
  type: 'ordered' | 'unordered';
  items: string[];
};

export function List(props: Props) {
  const { className, type, items } = props;

  return (
    <div className={clsx(className, styles['list'])}>
      {type === 'ordered' && (
        <ol>
          {items.map((item: string, index: number) => (
            <li
              key={index}
              dangerouslySetInnerHTML={{ __html: sanitizePostText(item) }}
            />
          ))}
        </ol>
      )}

      {type === 'unordered' && (
        <ul>
          {items.map((item: string, index: number) => (
            <li
              key={index}
              dangerouslySetInnerHTML={{ __html: sanitizePostText(item) }}
            />
          ))}
        </ul>
      )}
    </div>
  );
}
