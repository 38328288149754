import {
  API,
  BlockAPI,
  BlockTool,
  BlockToolConstructable,
} from '@editorjs/editorjs';

export type EmbedProviderResult = {
  html: string;
  url: string;
  scriptAfterHtmlInserted?: string;
};
export type EmbedProvider = (
  str: string,
) => Promise<EmbedProviderResult | undefined>;
export type EmbedToolState = {
  provider: string;
  input: string;
  url: string;
  html: string;
  scriptAfterHtmlInserted?: string;
  caption?: string;
};

export interface EmbedConfig {
  providers: Record<string, EmbedProvider>;
  blockClass: string;
  captionClass: string;
  inputClass: string;
}

export const Embed: BlockToolConstructable = class implements BlockTool {
  protected readonly api: API;
  protected readonly config: EmbedConfig;
  protected readonly block?: BlockAPI;
  protected state?: EmbedToolState;
  protected data: Record<any, any> = {};
  protected events: any;

  private readonly inputElem: HTMLInputElement;
  private readonly containerElem: HTMLDivElement;
  private readonly captionElem: HTMLInputElement;

  constructor(options: any) {
    this.events = options.events;
    this.api = options.api;
    this.config = {
      providers: options.config.providers || {},
      blockClass: options.config.blockClass || 'cdx-embed-block',
      captionClass: options.config.blockClass || 'cdx-embed-block__caption',
      inputClass: options.config.blockClass || 'cdx-embed-block__input',
    };
    this.block = options.block;

    if (
      typeof options.data === 'object' &&
      options.data !== null &&
      ['provider', 'html', 'url'].every((key) => key in options.data)
    ) {
      this.state = options.data;
    }

    this.containerElem = this.createContainerElem();
    this.inputElem = this.createInputElem();
    this.captionElem = this.createCaptionElem();

    this.refresh();
  }

  private createContainerElem() {
    const containerElem = document.createElement('div');
    containerElem.classList.add(this.api.styles.block);
    containerElem.classList.add(this.config.blockClass);

    return containerElem;
  }

  private createCaptionElem() {
    const inputElem = document.createElement('input');
    inputElem.classList.add(this.api.styles.input, this.config.captionClass);
    inputElem.placeholder = 'Caption';

    if (this.state?.caption) {
      inputElem.value = this.state.caption;
    }

    inputElem.addEventListener('input', (e: Event) => {
      if (!(e.target instanceof HTMLInputElement)) {
        return;
      }

      if (this.state) {
        this.state.caption = e.target.value.trim();
      }
    });

    return inputElem;
  }

  private createInputElem() {
    const inputElem = document.createElement('input');
    inputElem.classList.add(this.api.styles.input, this.config.inputClass);
    inputElem.placeholder =
      'Put a link to Twitter, Instagram, Youtube and e.t.c';

    inputElem.addEventListener('input', (e: Event) => {
      if (!(e.target instanceof HTMLInputElement)) {
        return;
      }

      this.parse(e.target.value)
        .then((result) => {
          if (!result) {
            return;
          }

          this.state = Object.assign(this.state || {}, result);
          this.renderState();
        })
        .catch(console.error);
    });

    return inputElem;
  }

  private renderInput() {
    this.containerElem.innerHTML = '';
    this.containerElem.appendChild(this.inputElem);

    setTimeout(() => this.inputElem.focus(), 100);
  }

  private renderState() {
    this.containerElem.innerHTML = this.state?.html || '';

    if (this.state?.scriptAfterHtmlInserted) {
      eval(this.state?.scriptAfterHtmlInserted);
    }

    this.containerElem.appendChild(this.captionElem);
  }

  private refresh() {
    if (!this.state) {
      return;
    }

    const input = this.state.input;

    this.parse(input)
      .then((result) => {
        const state: EmbedToolState | undefined = result
          ? {
              input,
              html: result.html,
              url: result.url,
              scriptAfterHtmlInserted: result.scriptAfterHtmlInserted,
              provider: result.provider,
            }
          : this.state;

        if (state) {
          this.state = Object.assign(this.state || {}, state);
          this.renderState();
        }
      })
      .catch(console.error);
  }

  render() {
    if (!this.state) {
      this.renderInput();
    } else {
      this.renderState();
    }

    return this.containerElem;
  }

  async parse(str: string): Promise<EmbedToolState | undefined> {
    for (const providerName in this.config.providers) {
      const provider = this.config.providers[providerName];

      if (!provider) {
        throw new Error("Provider doesn't exist");
      }

      const result = await provider(str);

      if (typeof result !== 'undefined') {
        return {
          input: str,
          provider: providerName,
          url: result.url,
          html: result.html,
          scriptAfterHtmlInserted: result.scriptAfterHtmlInserted,
        };
      }
    }
  }

  test: any;

  save() {
    return Object.assign(this.data, this.state);
  }

  static get toolbox() {
    return {
      title: 'Embed',
      icon: '<svg style="fill: none; width: 20px; height: 20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-code"><polyline points="16 18 22 12 16 6"></polyline><polyline points="8 6 2 12 8 18"></polyline></svg>',
    };
  }
};
