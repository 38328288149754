import { PostDto, PostsDto, useApi } from '../../shared/api';
import { useInfiniteQuery } from 'react-query';

type Props = {
  languages?: PostDto['languages'];
  tags?: string[];
  limit: number;
  initialData?: PostsDto[];
  staleTime?: number;
  refetchInterval?: number;
};

export function usePosts(props: Props) {
  const {
    languages,
    tags,
    limit,
    initialData,
    staleTime = 60000,
    refetchInterval = 60000,
  } = props;
  const api = useApi();

  const postsQuery = useInfiniteQuery(
    ['api/posts', { languages, tags, limit }],
    async (context) => {
      const page: number = context.pageParam || 1;

      const res = await api.blog.findPosts({
        tags,
        languages,
        limit,
        offset: (page - 1) * limit,
      });

      return res.data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const total = pages.reduce((cur, prev) => {
          return cur + prev.posts.length;
        }, 0);

        if (total < lastPage.total) {
          return pages.length + 1;
        }
      },
      initialData: () =>
        initialData && {
          pageParams: [undefined],
          pages: initialData,
        },
      staleTime,
      refetchInterval,
    },
  );

  return postsQuery;
}
