import styles from './styles.module.scss';
import Link from 'next/link';
import { ContentBlockDto } from '../../../shared/api';
import { clsx } from 'clsx';
import { Picture } from './picture';
import { Quote } from './quote';
import { Paragraph } from './paragraph';
import { Title } from './title';
import { Empty } from './empty';
import { getCover } from '../../helpers';

interface Props {
  url: string;
  title?: string;
  content: ContentBlockDto[];
  className?: string;
}

export function Preview(props: Props) {
  const { title, url, content, className } = props;
  const cover = getCover(content);

  const renderPreview = () => {
    switch (cover?.type) {
      case 'quote':
        return (
          <Quote
            alignment={cover.data['alignment']}
            text={cover.data['text']}
            caption={cover.data['caption']}
          />
        );
      case 'image':
        return <Picture url={cover.data['file'].url} />;
      case 'paragraph':
        return <Paragraph title={title} text={cover.data['text']} />;
      default:
        if (title) {
          return <Title title={title} />;
        } else {
          return <Empty />;
        }
    }
  };

  return (
    <article className={clsx(styles['post'], className)}>
      <Link className={styles['post__link']} href={url} />
      {renderPreview()}
    </article>
  );
}
