import { DraftDto, UpdateDraftDto, useApi } from '../../shared/api';
import { useMutation, useQueryClient } from 'react-query';

type UpdateDraftPayload = {
  id: string;
  data: UpdateDraftDto;
};

type Props = {
  onSuccess?: (draft: DraftDto) => void;
};

export function useUpdateDraft(props: Props = {}) {
  const { onSuccess } = props;
  const api = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation<DraftDto, unknown, UpdateDraftPayload>(
    'api/draft/update',
    async (payload) => {
      const res = await api.blog.updateDraft(payload.id, payload.data);

      return res.data;
    },
    {
      onSuccess(data) {
        queryClient.setQueryData(
          ['api/post', { id: data.id, secret: data.secret }],
          data,
        );

        queryClient.setQueryData(['api/post', { id: data.id }], data);

        if (onSuccess) {
          onSuccess(data);
        }
      },
    },
  );

  return mutation;
}
