import { fixWordBreaks } from './fix-word-breaks';

export function sanitizePostText(html: string) {
  return fixWordBreaks(
    html.replaceAll(
      /<a([\s]+[^<>]+)>/g,
      '<a$1 rel="nofollow noindex noreferrer noopener" target="_blank">',
    ),
  );
}
