import { PostDto } from '../../../../shared/api';
import { TupleOf } from '../../../../shared/types';
import { clsx } from 'clsx';
import styles from './styles.module.scss';
import { ImageLink } from './image-link';

type Props = {
  className?: string;
  posts: TupleOf<PostDto, 6>;
};

export function Layout6(props: Props) {
  const { posts, className } = props;

  return (
    <div className={clsx(styles['layout-6'], className)}>
      <div className={styles['layout-6__col']}>
        <ImageLink
          sizes="(max-width: 600px) 300px, 400px"
          className={clsx(styles['layout-6__item'], styles['layout-6__item-1'])}
          post={posts[0]}
        />
        <ImageLink
          sizes="(max-width: 600px) 300px, 400px"
          className={clsx(styles['layout-6__item'], styles['layout-6__item-2'])}
          post={posts[1]}
        />
      </div>
      <div className={styles['layout-6__col']}>
        <ImageLink
          sizes="(max-width: 600px) 300px, 400px"
          className={clsx(styles['layout-6__item'], styles['layout-6__item-3'])}
          post={posts[2]}
        />
        <ImageLink
          sizes="(max-width: 600px) 300px, 400px"
          className={clsx(styles['layout-6__item'], styles['layout-6__item-4'])}
          post={posts[3]}
        />
      </div>
      <div className={styles['layout-6__col']}>
        <ImageLink
          sizes="(max-width: 600px) 300px, 400px"
          className={clsx(styles['layout-6__item'], styles['layout-6__item-5'])}
          post={posts[4]}
        />
        <ImageLink
          sizes="(max-width: 600px) 300px, 400px"
          className={clsx(styles['layout-6__item'], styles['layout-6__item-6'])}
          post={posts[5]}
        />
      </div>
    </div>
  );
}
