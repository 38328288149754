import styles from './styles.module.scss';
import { clsx } from 'clsx';
import { ContentBlockDto } from '../../../shared/api';
import { Quote } from './quote';
import { Paragraph } from './paragraph';
import { Header } from './header';
import { Delimiter } from './delimiter';
import { Embed } from './embed';
import { List } from './list';
import { Unknown } from './unknown';
import { groupContent } from './helpers';
import { Images } from './images';
import { ImageViewerContext } from '../../../ui/image-viewer';
import { ExternalLink } from './external-link';
import { Personality } from './personality';

type Props = {
  className?: string;
  blocks: ContentBlockDto[];
};

export function Content(props: Props) {
  const { blocks, className } = props;
  const groups = groupContent(blocks);

  const sources = groups.reduce<string[]>((all, blocks) => {
    for (const block of blocks) {
      if (block.type === 'image') {
        all.push(block.data['file'].url);
      }
    }

    return all;
  }, []);

  return (
    <ImageViewerContext.Provider value={{ sources }}>
      <div className={clsx(styles['content'], className)}>
        {groups.map((group, groupIndex) => {
          if (group[0] && group[0].type !== 'image') {
            const block = group[0];

            switch (block.type) {
              case 'quote':
                return (
                  <Quote
                    key={groupIndex}
                    className={styles['content__block']}
                    text={block.data['text']}
                    caption={block.data['caption']}
                  />
                );
              case 'paragraph':
                return (
                  <Paragraph
                    key={groupIndex}
                    className={styles['content__block']}
                    text={block.data['text']}
                  />
                );
              case 'personality':
                return (
                  <Personality
                    name={block.data['name']}
                    description={block.data['description']}
                    link={block.data['link']}
                    image={block.data['photo']}
                    key={groupIndex}
                    className={styles['content__block']}
                  />
                );
              case 'header':
                return (
                  <Header
                    key={groupIndex}
                    className={styles['content__block']}
                    level={block.data['level']}
                    text={block.data['text']}
                  />
                );
              case 'externalLink':
                return (
                  <ExternalLink
                    key={groupIndex}
                    className={styles['content__block']}
                    title={block.data['meta']['title']}
                    description={block.data['meta']['description']}
                    image={block.data['meta']['image']?.['url']}
                    link={block.data['link']}
                  />
                );
              case 'delimiter':
                return (
                  <Delimiter
                    key={groupIndex}
                    className={styles['content__block']}
                  />
                );
              case 'embed':
                return (
                  <Embed
                    key={groupIndex}
                    className={styles['content__block']}
                    caption={block.data['caption']}
                    input={block.data['input']}
                    provider={block.data['provider']}
                    html={block.data['html']}
                  />
                );
              case 'list':
                return (
                  <List
                    key={groupIndex}
                    className={styles['content__block']}
                    type={block.data['style']}
                    items={block.data['items']}
                  />
                );
              default:
                return <Unknown key={groupIndex} />;
            }
          } else {
            const caption = group[group.length - 1]?.data['caption'];
            const nextGroup = groups[groupIndex + 1];
            const nextIsImage =
              nextGroup?.[nextGroup?.length - 1]?.['type'] === 'image';

            return (
              <Images
                key={groupIndex}
                blocks={group}
                caption={caption}
                className={clsx(
                  styles['content__block'],
                  nextIsImage &&
                    !caption &&
                    styles['content__block--small-bottom-margin'],
                )}
              />
            );
          }
        })}
      </div>
    </ImageViewerContext.Provider>
  );
}
