import { PostDto } from '../../../../shared/api';
import styles from './styles.module.scss';
import { clsx } from 'clsx';
import { getCover, getPostUrl, sanitizePostText } from '../../../helpers';
import { Title } from './title';
import { Quote } from './quote';
import { Personality } from './personality';

type Props = {
  className?: string;
  post: PostDto;
};

export function TextPost(props: Props) {
  const { post, className } = props;
  const cover = getCover(post.content);
  const url = getPostUrl({
    id: post.id,
    slug: post.slug || undefined,
  });

  return (
    <div className={clsx(className, styles['text-post'])}>
      {cover?.type !== 'quote' &&
        cover?.type !== 'personality' &&
        (post.title || (!post.title && !cover)) && (
          <Title
            id={`post-${post.id}`}
            url={url}
            title={post.title || undefined}
            translation={
              post.tags.includes('перевод') || post.tags.includes('translation')
            }
          />
        )}

      {cover?.type === 'paragraph' && (
        <div
          className={styles['text-post__text']}
          dangerouslySetInnerHTML={{
            __html: sanitizePostText(cover.data['text']),
          }}
        />
      )}

      {cover?.type === 'quote' && (
        <Quote
          url={url}
          text={cover.data['text']}
          caption={cover.data['caption']}
        />
      )}

      {cover?.type === 'personality' && (
        <Personality
          url={url}
          name={cover.data['name']}
          description={cover.data['description']}
          image={cover.data['photo']}
        />
      )}
    </div>
  );
}
