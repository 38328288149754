import { trimEnd } from 'lodash';
import { ContentBlockDto } from '../../shared/api';
import { getCover } from './get-cover';

export function getSeoDescription(
  content: ContentBlockDto[],
): string | undefined {
  let description: string | undefined;
  const cover = getCover(content);

  if (cover?.type === 'paragraph') {
    description = cover.data['text'];
  }

  if (typeof description !== 'string') {
    const block = content.find((block) => {
      return block.type === 'paragraph' && !block.tunes?.['hidden'];
    });

    description = block?.data['text'];
  }

  if (typeof description !== 'string' || description.length === 0) {
    return;
  }

  description = description.replaceAll(/<[^<>]+>/g, '').trim();

  if (description.length > 160) {
    description = trimEnd(description.substring(0, 160), '.').trimEnd() + '...';
  }

  return description;
}
