import styles from './styles.module.scss';
import { clsx } from 'clsx';

type Props = {
  className?: string;
};

export function Delimiter(props: Props) {
  const { className } = props;

  return <div className={clsx(className, styles['delimiter'])} />;
}
