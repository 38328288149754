import styles from './styles.module.scss';

type Props = {
  caption?: string;
  text: string;
  alignment: 'left' | 'center' | 'right';
};

export function Quote(props: Props) {
  const { text, caption, alignment } = props;

  return (
    <div
      className={[
        styles['quote'],
        alignment === 'center' && styles['quote--align-center'],
      ].join(' ')}
    >
      <blockquote dangerouslySetInnerHTML={{ __html: text }} />
      {caption && <cite dangerouslySetInnerHTML={{ __html: `– ${caption}` }} />}
    </div>
  );
}
