type DraftUrlComponents = {
  id: string;
  edit?: true;
  secret?: string;
};

export function getDraftUrl(components: DraftUrlComponents) {
  const { id, edit, secret } = components;

  let link = `/draft/${id}`;

  if (edit) {
    link += '/edit';
  }

  if (secret) {
    link += `?secret=${secret}`;
  }

  return link;
}
