import { DraftDto, useApi } from '../../shared/api';
import { useMutation } from 'react-query';

type PublishDraftPayload = {
  id: string;
};

type Props = {
  onSuccess?: (draft: DraftDto) => void;
};

export function usePublishDraft(props: Props = {}) {
  const { onSuccess } = props;
  const api = useApi();

  const mutation = useMutation<DraftDto, unknown, PublishDraftPayload>(
    'api/draft/publish',
    async (payload) => {
      const res = await api.blog.publishDraft(payload.id);
      return res.data;
    },
    {
      onSuccess,
    },
  );

  return mutation;
}
