import { MouseEvent, useCallback, useRef } from 'react';

type Props = {
  onClose?: () => void;
};

export function useModal(props: Props) {
  const { onClose } = props;

  const ref = useRef<HTMLDivElement>(null);
  const onClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const shouldClose =
        e.target instanceof Node && !ref.current?.contains(e.target);

      if (shouldClose && onClose) {
        onClose();
      }
    },
    [onClose],
  );

  return {
    ref,
    onClick,
  };
}
