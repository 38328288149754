import { PostDto } from '../../../shared/api';
import styles from './styles.module.scss';
import { TextPost } from './text-post';
import { groupPosts } from './helpers';
import { useI18n } from '../../../i18n';
import { Images } from './images';
import { getCover } from '../../helpers';
import { clsx } from 'clsx';

type Props = {
  posts: PostDto[];
};

export function Feed(props: Props) {
  const { posts } = props;

  const dateGroups = groupPosts(posts);
  const { i18n } = useI18n();

  return (
    <div className={styles['feed']}>
      {dateGroups.map((dateGroup) => {
        const monthName = i18n('months')[dateGroup.month - 1]?.[0];

        return (
          <div
            className={styles['feed__date-group']}
            key={`${dateGroup.month}-${dateGroup.year}`}
          >
            <div className={styles['feed__date']}>
              {monthName} {dateGroup.year}
            </div>
            {dateGroup.posts.map((postsGroup, postsGroupIndex) => {
              if (
                postsGroup.length === 1 &&
                postsGroup[0] &&
                getCover(postsGroup[0].content)?.type !== 'image'
              ) {
                return (
                  <TextPost
                    className={styles['feed__post']}
                    post={postsGroup[0]}
                    key={postsGroupIndex}
                  />
                );
              } else {
                return (
                  <Images
                    key={postsGroupIndex}
                    className={clsx(
                      styles['feed__post'],
                      styles['feed__post--images'],
                    )}
                    posts={postsGroup}
                  />
                );
              }
            })}
          </div>
        );
      })}
    </div>
  );
}
