import Link from 'next/link';
import { fixWordBreaks } from '../../../helpers';
import styles from './styles.module.scss';

type Props = {
  text: string;
  caption?: string;
  url: string;
};

export function Quote(props: Props) {
  const { text, caption, url } = props;

  const preparedText = fixWordBreaks(text);
  const preparedCaption = caption && fixWordBreaks(caption);

  return (
    <figure className={styles['quote']}>
      <blockquote
        className={styles['quote__container']}
        dangerouslySetInnerHTML={{ __html: preparedText }}
      />
      {preparedCaption && (
        <figcaption
          className={styles['quote__caption']}
          dangerouslySetInnerHTML={{ __html: `— ${preparedCaption}` }}
        ></figcaption>
      )}

      <Link className={styles['quote__link']} href={url} />
    </figure>
  );
}
