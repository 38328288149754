import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { CreateDraftDto, DraftDto, UpdateDraftDto } from '../../../shared/api';
import { Content, EditorApi } from '../../../ui/form/editor';
import { FormApi } from '../../../ui/form/form';
import { useRouter } from 'next/router';
import { LOCALES, useI18n } from '../../../i18n';
import { OptionType } from '../../../ui/form/select';
import {
  useUpdateDraft,
  useCreateDraft,
  usePublishDraft,
  useUnpublishDraft,
  useRemoveDraft,
  useResetDraft,
} from '../../queries';

export type WriterForm = {
  title: string;
  content: Content;
  languages: OptionType[];
  tags: OptionType[];
  draftSecret: string;
  publishedAt: string;
};

type Options = {
  onCreate?: (post: DraftDto) => void;
  draft?: DraftDto;
};

export function useWriter(options: Options = {}) {
  const [isOptionsModalVisible, setIsOptionsModalVisible] = useState(false);
  const [draft, setDraft] = useState<DraftDto | undefined>(options.draft);
  // TODO: fix
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});
  const editorRef = useRef<EditorApi>(null);
  const formRef = useRef<FormApi>(null);
  const router = useRouter();
  const updateDraft = useUpdateDraft({
    onSuccess(data) {
      setDraft(data);
    },
  });
  const createDraft = useCreateDraft({
    onSuccess(data) {
      setDraft(data);

      if (options.onCreate) {
        options.onCreate(data);
      }
    },
  });
  const publishDraft = usePublishDraft({
    onSuccess(data) {
      setDraft(data);
    },
  });
  const resetDraft = useResetDraft({
    onSuccess() {
      router.reload();
    },
  });
  const unpublishDraft = useUnpublishDraft({
    onSuccess(data) {
      setDraft(data);
    },
  });
  const removeDraft = useRemoveDraft({
    onSuccess() {
      router.push(draft?.postPublishedAt ? '/' : '/drafts');
    },
  });
  const [, setTime] = useState<number>();
  const { i18n, locale } = useI18n();

  const formLocaleOption = (locale: string) => {
    switch (locale) {
      case 'en':
        return {
          label: i18n('englishLanguage'),
          value: locale,
        };
      case 'ru':
        return {
          label: i18n('russianLanguage'),
          value: locale,
        };
      default:
        return {
          label: locale,
          value: locale,
        };
    }
  };
  const languageOptions: OptionType[] = LOCALES.map(formLocaleOption);
  const selectedLanguages: OptionType[] = (
    draft ? draft.languages : [locale]
  ).map(formLocaleOption);
  const selectedTags: OptionType[] = (draft ? draft.tags : []).map((tag) => ({
    label: tag,
    value: tag,
  }));

  const showOptionsModal = useCallback(() => {
    setIsOptionsModalVisible(true);
  }, []);

  const hideOptionsModal = useCallback(() => {
    setIsOptionsModalVisible(false);
  }, []);

  const publish = useCallback(() => {
    if (draft) {
      publishDraft.mutate({ id: draft?.id });
    }
  }, [publishDraft, draft]);

  const unpublish = useCallback(() => {
    if (draft) {
      unpublishDraft.mutate({ id: draft?.id });
      setIsOptionsModalVisible(false);
    }
  }, [unpublishDraft, draft]);

  const isUpdating =
    unpublishDraft.isLoading ||
    publishDraft.isLoading ||
    resetDraft.isLoading ||
    createDraft.isLoading ||
    updateDraft.isLoading ||
    removeDraft.isLoading;

  const remove = useCallback(() => {
    if (!draft || isUpdating) {
      return;
    }

    if (!confirm(i18n('areYouSure'))) {
      return;
    }

    setIsOptionsModalVisible(false);

    removeDraft.mutate({ id: draft.id });
  }, [draft, removeDraft, i18n, isUpdating]);

  const reset = useCallback(() => {
    if (!draft || isUpdating) {
      return;
    }

    if (!confirm(i18n('areYouSure'))) {
      return;
    }

    resetDraft.mutate({
      id: draft.id,
    });
  }, [draft, isUpdating, i18n, resetDraft]);

  useEffect(() => {
    const id = setInterval(() => {
      setTime(Date.now());
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  const focusOnContent = useCallback((e: KeyboardEvent) => {
    if (e.key.toLowerCase() === 'enter') {
      e.preventDefault();
      editorRef.current?.focus();
    }
  }, []);

  const submit = useCallback(() => {
    formRef.current?.submit();
  }, []);

  const save = useCallback(
    (data: WriterForm) => {
      if (isUpdating) {
        return;
      }

      setValidationErrors({});

      const title = data.title.trim();
      const secret = data.draftSecret.trim();
      const content = data.content.filter((block) => !!block.data);
      const languages = data.languages.map(
        (option) => option.value,
      ) as DraftDto['languages'];
      const tags = data.tags.map((option) => option.value);
      const publishedAt = isNaN(new Date(data.publishedAt).getTime())
        ? null
        : data.publishedAt;

      if (content.length === 0) {
        return;
      }

      const payload: CreateDraftDto & UpdateDraftDto = {
        title,
        content,
        languages,
        tags,
        secret,
        publishedAt,
      };

      if (draft) {
        updateDraft.mutate({
          id: draft.id,
          data: payload,
        });
      } else {
        createDraft.mutate(payload);
      }
    },
    [updateDraft, createDraft, draft, isUpdating],
  );

  return {
    save,
    submit,
    focusOnContent,
    formRef,
    remove,
    publish,
    unpublish,
    showOptionsModal,
    reset,
    draft,
    isUpdating,
    isOptionsModalVisible,
    hideOptionsModal,
    validationErrors,
    selectedLanguages,
    languageOptions,
    selectedTags,
    editorRef,
    i18n,
  };
}
