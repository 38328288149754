type PostUrlComponents = {
  id: string;
  slug?: string;
};

export function getPostUrl(components: PostUrlComponents) {
  const { id, slug } = components;

  return `/post/${slug ? `${slug}-` : ''}${id}`;
}
