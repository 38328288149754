export function fixWordBreaks(html: string): string {
  const noBreak = '(из-за|из-под)';
  const withNext =
    '(а|в|во|въ|да|и|к|ко|къ|о|об|обо|объ|с|со|cъ|у|без|для|до|за|из|изъ|или|на|над|не|ни|но|от|отъ|по|под|при|про|a|an|as|and|&|at|but|by|for|in|no|not|of|or|per|the|to|$|€|£)';
  const withPrev = '(б|бы|ж|же|ль|ли|₽|\\$|€|£)';

  let result = html;

  for (let i = 0; i < 10; i++) {
    let fixedHtml = result;

    fixedHtml = fixedHtml
      .replaceAll(
        new RegExp(`^${noBreak}([\\s<,.?!:;"'])`, 'iug'),
        '[<nowrap>]$1[</nowrap>]$2',
      )
      .replaceAll(
        new RegExp(`([\\s,.>?!:;"'])${noBreak}([\\s,<.?!:;"'])`, 'iug'),
        '$1[<nowrap>]$2[</nowrap>]$3',
      )
      .replaceAll(
        new RegExp(`([\\s,>.?!:;"'])${noBreak}$`, 'iug'),
        '$1[<nowrap>]$2[</nowrap>]',
      );

    fixedHtml = fixedHtml
      .replaceAll(new RegExp(`^${withNext} `, 'iug'), '$1&nbsp;')
      .replaceAll(
        new RegExp(`([\\s,.?!>:;"'])${withNext} `, 'iug'),
        '$1$2&nbsp;',
      );

    fixedHtml = fixedHtml
      .replaceAll(new RegExp(` ${withPrev}$`, 'iug'), '&nbsp;$1')
      .replaceAll(
        new RegExp(` ${withPrev}([\\s,.?!:;"'<])`, 'iug'),
        '&nbsp;$1$2',
      );

    if (fixedHtml === result) {
      break;
    } else {
      result = fixedHtml;
    }
  }

  result = result.replaceAll(
    '[<nowrap>]',
    '<span style="white-space: nowrap">',
  );
  result = result.replaceAll('[</nowrap>]', '</span>');

  return result;
}
