import { PostDto } from '../../../../shared/api';
import { TupleOf } from '../../../../shared/types';
import { Grid } from './grid';
import { Layout5 } from './layout5';
import { Layout6 } from './layout6';
import { Layout7 } from './layout7';
import { Layout8 } from './layout8';

type Props = {
  className?: string;
  posts: PostDto[];
};

function hasLength<L extends number>(
  posts: PostDto[],
  length: L,
): posts is TupleOf<PostDto, L> {
  return posts.length === length;
}

export function Images(props: Props) {
  const { posts, className } = props;

  if (hasLength(posts, 5)) {
    return <Layout5 className={className} posts={posts} />;
  } else if (hasLength(posts, 6)) {
    return <Layout6 className={className} posts={posts} />;
  } else if (hasLength(posts, 7)) {
    return <Layout7 className={className} posts={posts} />;
  } else if (hasLength(posts, 8)) {
    return <Layout8 className={className} posts={posts} />;
  }

  return <Grid className={className} posts={posts} />;
}
