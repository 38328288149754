import styles from './styles.module.scss';
import { Content } from '../content';
import { ContentBlockDto } from '../../../shared/api';
import { Meta } from './meta';
import { Title } from './title';
import { getCover } from '../../helpers';

type Props = {
  tags?: string[];
  title?: string;
  date?: Date;
  content: ContentBlockDto[];
};

export function Post(props: Props) {
  const { date, tags, title, content } = props;

  const cover = getCover(content);
  const hideTitle = cover?.type === 'personality' && content[0] === cover;

  return (
    <div className={styles['post']}>
      {date && <Meta className={styles['post__meta']} date={date} />}
      {title && !hideTitle && (
        <Title
          id={'title'}
          translation={
            tags?.includes('перевод') || tags?.includes('translation')
          }
          title={title}
        />
      )}
      <Content className={styles['post__content']} blocks={content} />
    </div>
  );
}
