import { escape } from 'lodash';
import Link from 'next/link';
import { BsTranslate } from 'react-icons/bs';
import { useI18n } from '../../../../i18n';
import { Tooltip } from '../../../../ui/tooltip';
import { fixWordBreaks } from '../../../helpers';
import styles from './styles.module.scss';

type Props = {
  id: string;
  title?: string;
  url: string;
  translation?: boolean;
};

export function Title(props: Props) {
  const { id, url, title, translation } = props;

  const { i18n } = useI18n();

  const preparedTitle = fixWordBreaks(escape(title || i18n('emptyPreview')));

  return (
    <>
      <h2 className={styles['title']}>
        {translation && (
          <BsTranslate
            id={`${id}-translation-icon`}
            className={styles['title__translation-icon']}
          />
        )}
        <Link href={url} dangerouslySetInnerHTML={{ __html: preparedTitle }} />
      </h2>
      {translation && (
        <Tooltip
          anchorId={`${id}-translation-icon`}
          content={i18n('thisIsTranslation')}
        />
      )}
    </>
  );
}
