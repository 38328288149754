import Image from 'next/image';
import Link from 'next/link';
import { PostDto } from '../../../../shared/api';
import styles from './styles.module.scss';
import { canImageBeOptimized } from '../../../../shared/image';
import { clsx } from 'clsx';
import { getCover, getPostUrl } from '../../../helpers';
import { CSSProperties, useMemo } from 'react';

type Props = {
  className?: string;
  post: PostDto;
  sizes?: string;
};

export function ImageLink(props: Props) {
  const { post, className, sizes } = props;

  const cover = getCover(post.content);
  const averageColor = cover?.data['file']?.['image']?.['averageColor'];

  const imageStyle = useMemo(() => {
    const style: CSSProperties = {};

    if (averageColor) {
      style.background = `linear-gradient(-45deg,#282c35,${averageColor},#282c35)`;
      style.backgroundSize = `400% 400%`;
    }

    return style;
  }, [averageColor]);

  if (cover?.type !== 'image') {
    return <div>Not an image</div>;
  }

  return (
    <Link
      style={imageStyle}
      className={clsx(className, styles['link'])}
      key={post.id}
      href={getPostUrl({
        id: post.id,
        slug: post.slug || undefined,
      })}
    >
      <Image
        className={styles['image']}
        alt={''}
        fill={true}
        sizes={sizes}
        src={cover.data['file'].url}
        unoptimized={!canImageBeOptimized(cover.data['file'].url)}
      />
    </Link>
  );
}
