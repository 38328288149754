import { ContentBlockDto } from '../../../shared/api';

export function groupContent(content: ContentBlockDto[]): ContentBlockDto[][] {
  return content.reduce<ContentBlockDto[][]>((groups, block) => {
    const prevGroup = groups[groups.length - 1];
    const prevBlock = prevGroup?.[prevGroup.length - 1];

    if (block.tunes?.['hidden']) {
      return groups;
    }

    if (
      prevBlock?.type === 'image' &&
      block.type === 'image' &&
      !block.data['group']
    ) {
      prevGroup?.push(block);
    } else {
      groups.push([block]);
    }

    return groups;
  }, []);
}
