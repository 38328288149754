import { useApi } from '../../shared/api';
import { useMutation } from 'react-query';

type ViewPostPayload = {
  id: string;
};

export function useViewPost() {
  const api = useApi();

  const mutation = useMutation<void, unknown, ViewPostPayload>(
    'api/post',
    async (payload) => {
      await api.blog.viewPost(payload.id);
    },
  );

  return mutation;
}
