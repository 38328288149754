import styles from './styles.module.scss';
import { clsx } from 'clsx';
import { fixWordBreaks } from '../../helpers';

type Props = {
  className?: string;
  text: string;
  caption?: string;
};

export function Quote(props: Props) {
  const { className, text, caption } = props;

  const preparedText = fixWordBreaks(text);
  const preparedCaption = caption && fixWordBreaks(caption);

  return (
    <figure className={clsx(className, styles['quote'])}>
      <blockquote
        className={styles['quote__container']}
        dangerouslySetInnerHTML={{ __html: preparedText }}
      />
      {caption && (
        <figcaption
          className={styles['quote__caption']}
          dangerouslySetInnerHTML={{
            __html: `— ${preparedCaption}`,
          }}
        />
      )}
    </figure>
  );
}
