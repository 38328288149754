import {
  DraftDto,
  DraftsDto,
  useApi,
  useApiCredentials,
  useApiInfiniteQuery,
} from '../../shared/api';

type Props = {
  languages?: DraftDto['languages'];
  tags?: string[];
  limit: number;
  initialData?: DraftsDto[];
};

export function useDrafts(props: Props) {
  const { languages, tags, limit, initialData } = props;
  const api = useApi();
  const { hasToken } = useApiCredentials();

  const postsQuery = useApiInfiniteQuery(
    ['api/drafts', { languages, tags, limit }],
    async (context) => {
      const page: number = context.pageParam || 1;

      const res = await api.blog.findDrafts({
        tags,
        languages,
        limit,
        offset: (page - 1) * limit,
      });

      return res.data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        const total = pages.reduce((cur, prev) => {
          return cur + prev.drafts.length;
        }, 0);

        if (total < lastPage.total) {
          return pages.length + 1;
        }
      },
      initialData: () =>
        initialData && {
          pageParams: [undefined],
          pages: initialData,
        },
      enabled: hasToken,
    },
  );

  return postsQuery;
}
