import styles from './styles.module.scss';
import Image from 'next/image';
import { canImageBeOptimized } from '../../../shared/image';

type Props = {
  url: string;
};

export function Picture(props: Props) {
  const { url } = props;

  return (
    <div className={styles['image']}>
      <Image
        sizes={'(max-width: 499px) 500px, (max-width: 749px) 350px, 300px'}
        alt={''}
        fill={true}
        unoptimized={!canImageBeOptimized(url)}
        src={url}
      />
    </div>
  );
}
