import styles from './styles.module.scss';
import { ReactNode } from 'react';
import { clsx } from 'clsx';
import { useModal } from './use-modal';

type Props = {
  children: ReactNode[] | ReactNode;
  className?: string;
  visible?: boolean;
  onClose?: () => void;
};

export function Modal(props: Props) {
  const { onClick, ref } = useModal(props);

  return (
    <div
      onClick={onClick}
      className={clsx(
        styles['container'],
        props.className,
        !props.visible && styles['container_hidden'],
      )}
    >
      <div className={styles['background']} />
      <div className={styles['container_modal']}>
        <div ref={ref} className={styles['modal']}>
          {props.children}
        </div>
      </div>
    </div>
  );
}
