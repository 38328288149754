type PublicationDate = {
  day: number;
  month: string;
  year: number;
  currentYear: number;
  minutes: number;
  hours: number;
};
export function getPublicationDateString(date: PublicationDate) {
  const { day, hours, month, minutes, year, currentYear } = date;

  let dateString = `${day} ${month}`;

  if (year !== currentYear) {
    dateString += ` ${year}`;
  }

  dateString += `, ${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;

  return dateString;
}
