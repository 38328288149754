import { CreateDraftDto, DraftDto, useApi } from '../../shared/api';
import { useMutation } from 'react-query';

type Props = {
  onSuccess?: (draft: DraftDto) => void;
};

export function useCreateDraft(props: Props = {}) {
  const { onSuccess } = props;
  const api = useApi();

  const mutation = useMutation<DraftDto, unknown, CreateDraftDto>(
    ['api/draft/create'],
    async (payload) => {
      const res = await api.blog.createDraft(payload);

      return res.data;
    },
    {
      onSuccess,
    },
  );

  return mutation;
}
