import styles from './styles.module.scss';
import { clsx } from 'clsx';
import { sanitizePostText } from '../../helpers';

type Props = {
  className?: string;
  text: string;
};

export function Paragraph(props: Props) {
  const { className, text } = props;

  const santitizedText = sanitizePostText(text);

  return (
    <p
      dangerouslySetInnerHTML={{ __html: santitizedText }}
      className={clsx(className, styles['paragraph'])}
    />
  );
}
