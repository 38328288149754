import styles from './styles.module.scss';
import { clsx } from 'clsx';
import { CSSProperties, useMemo } from 'react';
import { ImageViewer } from '../../../../ui/image-viewer';

type Props = {
  className?: string;
  withBorder?: boolean;
  url: string;
  caption?: string;
  withBackground?: boolean;
  averageColor?: string;
  sizes?: string;
  width?: number;
  height?: number;
};

export function Picture(props: Props) {
  const {
    width,
    height,
    className,
    averageColor,
    withBorder,
    withBackground,
    caption,
    url,
    sizes,
  } = props;

  const imageStyle = useMemo(() => {
    const style: CSSProperties = {};

    if (averageColor) {
      style.background = `linear-gradient(-45deg,#282c35,${averageColor},#282c35)`;
      style.backgroundSize = `400% 400%`;
    }

    return style;
  }, [averageColor]);

  return (
    <div
      className={clsx(
        className,
        styles['picture'],
        withBorder && styles['picture--withorder'],
        withBackground && styles['picture--with-background'],
      )}
    >
      <div style={imageStyle} className={styles['picture__image']}>
        <ImageViewer
          width={width || 1}
          height={height || 1}
          alt={caption || ''}
          sizes={sizes}
          src={url}
        />
      </div>
    </div>
  );
}
