import Image from 'next/image';
import { useCallback, useEffect, useState } from 'react';
import { canImageBeOptimized } from '../../shared/image';
import styles from './styles.module.scss';
import { clsx } from 'clsx';
import { ImageModal } from './image-modal';

type Props = {
  width?: number;
  height?: number;
  alt?: string;
  src: string;
  nextSrc?: string;
  prevSrc?: string;
  sizes?: string;
  className?: string;
};

export function ImageViewer(props: Props) {
  const { className, sizes, src, alt, width, height } = props;
  const unoptimized = !canImageBeOptimized(src);

  const [isZoomed, setIsZoomed] = useState(false);
  const [isBrowser, setIsBrowser] = useState(false);

  const reZoom = useCallback(() => {
    setIsZoomed(!isZoomed);
  }, [isZoomed]);

  useEffect(() => {
    setIsBrowser(typeof window !== 'undefined');
  }, [isBrowser]);

  return (
    <>
      {isZoomed && (
        <ImageModal
          width={width || 1}
          height={height || 1}
          onClose={reZoom}
          src={src}
        />
      )}
      <Image
        onClick={reZoom}
        className={clsx(className, styles['image'])}
        width={width || 1}
        height={height || 1}
        alt={alt || ''}
        sizes={sizes}
        src={src}
        unoptimized={unoptimized}
      />
    </>
  );
}
