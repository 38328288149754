import styles from './styles.module.scss';

type Props = {
  title?: string;
  text: string;
};

export function Paragraph(props: Props) {
  const { title, text } = props;

  return (
    <div className={styles['paragraph']}>
      {title && <h3 dangerouslySetInnerHTML={{ __html: title }} />}
      <p dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}
