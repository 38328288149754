import { ContentBlockDto } from '../../shared/api';
import { getCover } from './get-cover';

export function getSeoImage(content: ContentBlockDto[]): string | undefined {
  const cover = getCover(content);

  if (cover?.type === 'image') {
    return cover.data['file'].url;
  }

  const block = content.find((block) => {
    return block.type === 'image' && !block.tunes?.['hidden'];
  });

  if (block) {
    return block.data['file'].url;
  }
}
