import { escape } from 'lodash';
import { BsTranslate } from 'react-icons/bs';
import { useI18n } from '../../../i18n';
import { Tooltip } from '../../../ui/tooltip';
import { fixWordBreaks } from '../../helpers';
import styles from './styles.module.scss';

type Props = {
  id: string;
  title: string;
  translation?: boolean;
};

export function Title(props: Props) {
  const { id, title, translation } = props;
  const { i18n } = useI18n();

  const preparedTitle = fixWordBreaks(escape(title));

  return (
    <>
      <h1 className={styles['title']}>
        {' '}
        {translation && (
          <BsTranslate
            id={`${id}-translation-icon`}
            className={styles['title__translation-icon']}
          />
        )}
        <span dangerouslySetInnerHTML={{ __html: preparedTitle }} />
      </h1>
      {translation && (
        <Tooltip
          anchorId={`${id}-translation-icon`}
          content={i18n('thisIsTranslation')}
        />
      )}
    </>
  );
}
