import { DraftDto, useApi } from '../../shared/api';
import { useMutation } from 'react-query';

type UnpublishDraftPayload = {
  id: string;
};

type Props = {
  onSuccess?: (draft: DraftDto) => void;
};

export function useUnpublishDraft(props: Props = {}) {
  const { onSuccess } = props;
  const api = useApi();

  const mutation = useMutation<DraftDto, unknown, UnpublishDraftPayload>(
    'api/draft/unpublish',
    async (payload) => {
      const res = await api.blog.unpublishDraft(payload.id);
      return res.data;
    },
    {
      onSuccess,
    },
  );

  return mutation;
}
