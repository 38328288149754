import { clsx } from 'clsx';
import { ImageViewer } from '../../../ui/image-viewer';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  name: string;
  description: string;
  link?: string;
  image?: string;
};

export function Personality(props: Props) {
  const { className, image, description, name, link } = props;

  return (
    <div className={clsx(className, styles['personality'])}>
      <div className={styles['personality__content']}>
        <div className={styles['personality__name']}>{name}</div>
        <div className={styles['personality__description']}>{description}</div>
        {link && (
          <a
            target={'_blank'}
            rel="nofollow noindex noreferrer noopener"
            href={link}
            className={styles['personality__link']}
          >
            {link}
          </a>
        )}
      </div>
      {image && (
        <div className={styles['personality__image-container']}>
          <ImageViewer
            className={styles['personality__image']}
            alt={name}
            src={image}
            sizes={'(max-width: 499px) 500px, (max-width: 749px) 350px, 300px'}
          />
        </div>
      )}
    </div>
  );
}
