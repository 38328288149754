import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { canImageBeOptimized } from '../../../shared/image';
import { ImageViewerContext } from '../image-viewer-context';
import styles from './styles.module.scss';

type Props = {
  onClose?: () => void;
  src: string;
};

export function useImageModal(props: Props) {
  const { onClose, src } = props;

  const { sources } = useContext(ImageViewerContext);
  const currentSources = useMemo(() => {
    const allSources = [...sources];

    if (!allSources.includes(src)) {
      allSources.push(src);
    }

    return allSources;
  }, [sources, src]);

  const [currentSrcIndex, setCurrentSrcIndex] = useState(
    currentSources.findIndex((value) => value === src),
  );

  const currentSrc = currentSources[currentSrcIndex] || src;
  const unoptimized = !canImageBeOptimized(src);

  const next = useCallback(() => {
    const nextIndex =
      currentSrcIndex + 1 >= currentSources.length ? 0 : currentSrcIndex + 1;

    setCurrentSrcIndex(nextIndex);
  }, [currentSrcIndex, currentSources]);

  const prev = useCallback(() => {
    const prevIndex =
      currentSrcIndex - 1 < 0 ? currentSources.length - 1 : currentSrcIndex - 1;

    setCurrentSrcIndex(prevIndex);
  }, [currentSrcIndex, currentSources]);

  useEffect(() => {
    const unscrollableClass = styles['unscrollable'];

    if (typeof unscrollableClass !== 'string') {
      return;
    }

    const { scrollY } = window;

    document.body.parentElement?.classList.add(unscrollableClass);
    if (scrollY !== 0) {
      document.body.scrollTop = scrollY;
    }

    return () => {
      const { scrollTop } = document.body;
      document.body.parentElement?.classList.remove(unscrollableClass);

      window.scrollTo({
        top: scrollTop,
      });
    };
  });

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (['ArrowLeft', 'Left'].includes(e.key)) {
        prev();
      } else if (['ArrowRight', ' ', 'Right'].includes(e.key)) {
        next();
      } else if (['Esc', 'Escape', 'Backspace'].includes(e.key) && onClose) {
        onClose();
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onClose, prev, next]);

  const swipeable = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    onSwipedUp: onClose,
  });

  return {
    currentSrc,
    unoptimized,
    swipeable,
  };
}
