import { StaticImageData } from 'next/image';

interface StaticRequire {
  default: StaticImageData;
}

type StaticImport = StaticRequire | StaticImageData;

export function canImageBeOptimized(src: string | StaticImport) {
  const domains = (process.env['NEXT_PUBLIC_IMAGE_DOMAINS'] || '')
    .split(',')
    .map((domain) => domain.trim());

  if (typeof src === 'string') {
    const url = new URL(src);
    return domains.indexOf(url.hostname) !== -1;
  }

  return true;
}
