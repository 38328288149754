import { ContentBlockDto } from '../../../../shared/api';
import { clsx } from 'clsx';
import styles from './styles.module.scss';
import { Picture } from './picture';
import { fixWordBreaks } from '../../../helpers';

type Props = {
  blocks: ContentBlockDto[];
  className?: string;
  caption?: string;
};

export function Images(props: Props) {
  const { caption, blocks, className } = props;
  const preparedCaption = caption && fixWordBreaks(caption);

  const cols = Array.from(
    new Array(blocks.length > 4 ? 5 : blocks.length).keys(),
  );
  const rows = Array.from(
    new Array(Math.ceil(blocks.length / cols.length)).keys(),
  );

  const sizes: Record<number, string> = {
    1: '(max-width: 500px) 500px, 900px',
    2: '(max-width: 500px) 200px, 400px',
    3: '(max-width: 500px) 150px, 300px',
  };

  return (
    <div
      className={clsx(
        className,
        styles[`grid`],
        styles[`grid--cols-${cols.length}`],
      )}
    >
      {rows.map((row) => {
        return (
          <div key={row} className={styles['grid__row']}>
            {cols.map((col) => {
              const block = blocks[row * cols.length + col];

              const width = block?.data['file']?.['image']?.['width'];
              const height = block?.data['file']?.['image']?.['height'];
              const averageColor =
                block?.data['file']?.['image']?.['averageColor'];

              return (
                <div key={col} className={styles['grid__col']}>
                  {block && (
                    <Picture
                      averageColor={averageColor}
                      className={styles['grid__item']}
                      key={`${row}-${col}`}
                      url={block.data['file'].url}
                      sizes={
                        sizes[cols.length] || '(max-width: 500px) 200px, 400px'
                      }
                      width={width}
                      height={height}
                      withBackground={block.data['withBackground']}
                      withBorder={block.data['withBorder']}
                      caption={block.data['caption']}
                    />
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
      {preparedCaption && (
        <div
          className={clsx(styles['caption'])}
          dangerouslySetInnerHTML={{
            __html: preparedCaption,
          }}
        />
      )}
    </div>
  );
}
