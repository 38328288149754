import { useApi } from '../../shared/api';
import { useMutation } from 'react-query';

type RemoveDraftPayload = {
  id: string;
};

type Props = {
  onSuccess?: () => void;
};

export function useRemoveDraft(props: Props = {}) {
  const { onSuccess } = props;
  const api = useApi();

  const mutation = useMutation<void, unknown, RemoveDraftPayload>(
    'api/draft/remove',
    async (payload) => {
      await api.blog.removeDraft(payload.id);
    },
    {
      onSuccess,
    },
  );

  return mutation;
}
