import styles from './styles.module.scss';
import { clsx } from 'clsx';
import { escape } from 'lodash';
import { fixWordBreaks } from '../../helpers';

type Props = {
  className?: string;
  title?: string;
  link: string;
  image?: string;
  description?: string;
};

export function ExternalLink(props: Props) {
  const { className, image, link, title, description } = props;
  const preparedTitle = title && fixWordBreaks(escape(title));
  const preparedDescription = description && fixWordBreaks(escape(description));

  let anchor = link;

  try {
    const url = new URL(link);

    anchor = url.host;
  } catch (e) {
    console.log(e);
  }

  return (
    <div className={clsx(className, styles['link'])}>
      <a
        className={styles['link__content']}
        href={link}
        rel="nofollow noindex noreferrer noopener"
        target={'_blank'}
      >
        {image && (
          <div
            className={styles['link__image']}
            style={{ backgroundImage: `url(${image})` }}
          />
        )}

        {preparedTitle && (
          <div
            className={styles['link__title']}
            dangerouslySetInnerHTML={{ __html: preparedTitle }}
          />
        )}

        {preparedDescription && (
          <p
            className={styles['link__description']}
            dangerouslySetInnerHTML={{ __html: preparedDescription }}
          />
        )}
        <span className={styles['link__anchor']}>{anchor}</span>
      </a>
    </div>
  );
}
