import styles from './styles.module.scss';
import { clsx } from 'clsx';
import { fixWordBreaks } from '../../helpers';

type Props = {
  className?: string;
  text: string;
  level: number;
};

export function Header(props: Props) {
  const { className, text, level } = props;

  const preparedText = fixWordBreaks(text);

  switch (level) {
    case 1:
      return (
        <h1
          dangerouslySetInnerHTML={{ __html: preparedText }}
          className={clsx(className, styles['header'])}
        />
      );
    case 2:
      return (
        <h2
          dangerouslySetInnerHTML={{ __html: preparedText }}
          className={clsx(className, styles['header'])}
        />
      );
    case 3:
      return (
        <h3
          dangerouslySetInnerHTML={{ __html: preparedText }}
          className={clsx(className, styles['header'])}
        />
      );

    case 4:
      return (
        <h4
          dangerouslySetInnerHTML={{ __html: preparedText }}
          className={clsx(className, styles['header'])}
        />
      );

    case 5:
      return (
        <h5
          dangerouslySetInnerHTML={{ __html: preparedText }}
          className={clsx(className, styles['header'])}
        />
      );
    default:
      return (
        <h6
          dangerouslySetInnerHTML={{ __html: preparedText }}
          className={clsx(className, styles['header'])}
        />
      );
  }
}
