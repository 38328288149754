import styles from './styles.module.scss';

type Props = {
  title: string;
};

export function Title(props: Props) {
  const { title } = props;

  return (
    <div className={styles['title']}>
      <h3 dangerouslySetInnerHTML={{ __html: title }} />
    </div>
  );
}
