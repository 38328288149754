import { Tooltip as ReactTooltip } from 'react-tooltip';
import styles from './styles.module.scss';

type Props = {
  content?: string;
  anchorId?: string;
};

export function Tooltip(props: Props) {
  return <ReactTooltip {...props} className={styles['tooltip']} />;
}
