import { DraftDto } from '../../../shared/api';
import styles from './styles.module.scss';
import {
  Form,
  Button,
  CreatableSelect,
  Textarea,
  Input,
} from '../../../ui/form';
import { toRelative } from './helpers';
import { clsx } from 'clsx';
import dynamic from 'next/dynamic';
import { Modal } from '../../../ui/modal';
import { Main } from '../../../ui/main';
import { Select } from '../../../ui/form/select';
import { useWriter, WriterForm } from './use-writer';
import { getPostUrl } from '../../helpers';

const Editor = dynamic(
  () => import('../../../ui/form/editor').then((res) => res.Editor),
  { ssr: false },
);

type Props = {
  draft?: DraftDto;
  onCreate?: (post: DraftDto) => void;
};

export function Writer(props: Props) {
  const {
    formRef,
    save,
    submit,
    draft,
    publish,
    isUpdating,
    showOptionsModal,
    reset,
    isOptionsModalVisible,
    hideOptionsModal,
    validationErrors,
    selectedLanguages,
    languageOptions,
    selectedTags,
    unpublish,
    remove,
    focusOnContent,
    editorRef,
    i18n,
  } = useWriter({ draft: props.draft, onCreate: props.onCreate });

  return (
    // @ts-expect-error: TODO: Fix
    <Form<WriterForm> apiRef={formRef} onSubmit={save}>
      <Main
        rightSidebar={
          draft && (
            <div className={clsx(styles['sidebar'])}>
              <div className={styles['sidebar__topContainer']}>
                <div className={styles['sidebar__top']}>
                  {!draft.postPublished && (
                    <Button
                      className={styles['sidebar__item']}
                      type={'button'}
                      disabled={isUpdating}
                      onClick={publish}
                      block={true}
                    >
                      {i18n('publishDraft')}
                    </Button>
                  )}

                  {draft.postPublished && !draft.published && (
                    <Button
                      className={styles['sidebar__item']}
                      type={'button'}
                      disabled={isUpdating}
                      onClick={publish}
                      block={true}
                    >
                      {i18n('publishDraftChanges')}
                    </Button>
                  )}

                  {!draft.published && (
                    <Button
                      className={styles['sidebar__item']}
                      type={'link'}
                      style={'outlined'}
                      target={'_blank'}
                      disabled={isUpdating}
                      href={`/draft/${draft.id}?secret=${draft.secret}`}
                      block={true}
                    >
                      {i18n('previewDraft')}
                    </Button>
                  )}

                  {draft.postPublished && (
                    <Button
                      className={styles['sidebar__item']}
                      type={'link'}
                      target={'_blank'}
                      style={'outlined'}
                      disabled={isUpdating}
                      href={getPostUrl({
                        id: draft.id,
                        slug: draft.postSlug || undefined,
                      })}
                      block={true}
                    >
                      {i18n('openPost')}
                    </Button>
                  )}

                  {!draft.postPublished && (
                    <div
                      className={clsx(
                        styles['sidebar__item'],
                        styles['sidebar__label'],
                      )}
                    >
                      Saved {toRelative(draft.updatedAt)}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles['sidebar__bottom']}>
                {draft.postPublished && !draft.published && (
                  <Button
                    className={styles['sidebar__item']}
                    type={'button'}
                    style={'outlined'}
                    disabled={isUpdating}
                    onClick={reset}
                    block={true}
                  >
                    {i18n('resetDraft')}
                  </Button>
                )}
                <Button
                  className={clsx(
                    styles['sidebar__item'],
                    styles['sidebar__item_large'],
                  )}
                  style={'void'}
                  type={'button'}
                  loading={isUpdating}
                  onClick={showOptionsModal}
                  block={true}
                >
                  •••
                </Button>
              </div>
            </div>
          )
        }
      >
        <Modal
          className={styles['modal']}
          visible={isOptionsModalVisible}
          onClose={hideOptionsModal}
        >
          <Select
            placeholder={i18n('selectPlaceholder')}
            validationError={
              Array.isArray(validationErrors['languages'])
                ? validationErrors['languages'].map((error) =>
                    error.key === 'arrayNotEmpty'
                      ? i18n('draftLanguagesIsRequired')
                      : error.message,
                  )
                : undefined
            }
            name={'languages'}
            label={i18n('draftLanguages')}
            defaultValue={selectedLanguages}
            onChange={submit}
            options={languageOptions}
            multi={true}
          />
          <CreatableSelect
            placeholder={i18n('selectPlaceholder')}
            name={'tags'}
            label={i18n('draftTags')}
            multi={true}
            onChange={submit}
            defaultValue={selectedTags}
          />
          <Input
            label={i18n('draftSecret')}
            name={'draftSecret'}
            defaultValue={draft?.secret || ''}
            onChange={submit}
          />
          <Input
            label={i18n('draftPublishDate')}
            name={'publishedAt'}
            defaultValue={draft?.publishedAt || ''}
            onChange={submit}
          />
          {draft && draft.published && (
            <Button
              disabled={isUpdating}
              className={styles['modal__action']}
              style={'outlined'}
              block={true}
              onClick={unpublish}
              type={'button'}
            >
              {i18n('unpublishDraft')}
            </Button>
          )}
          <Button
            onClick={remove}
            disabled={isUpdating}
            className={styles['modal__action']}
            style={'danger'}
            block={true}
            type={'button'}
          >
            {i18n('deleteDraft')}
          </Button>
        </Modal>
        <div className={styles['form']}>
          <Textarea
            name={'title'}
            defaultValue={props.draft?.title || undefined}
            onInput={submit}
            autoSize={true}
            onKeyDown={focusOnContent}
            className={styles['form__title']}
            placeholder={i18n('draftTitlePlaceholder')}
          />
          <div className={styles['form__content']}>
            <Editor
              className={'writer'}
              name={'content'}
              defaultValue={props.draft && props.draft.content}
              placeholder={i18n('draftContentPlaceholder')}
              apiRef={editorRef}
              onChange={submit}
            />
          </div>
        </div>
      </Main>
    </Form>
  );
}
