import { ContentBlockDto, PostDto } from '../../../shared/api';
import { getCover } from '../../helpers';

type DateGroup = {
  month: number;
  year: number;
  posts: PostDto[][];
};

export function groupPosts(posts: PostDto[]): DateGroup[] {
  let prevDateGroup: DateGroup | undefined;
  let prevPostCover: ContentBlockDto | undefined = undefined;

  return posts.reduce<DateGroup[]>((groups, post) => {
    const postCover = getCover(post.content);
    const postPublishedAt = new Date(post.publishedAt);

    let currentDateGroup = prevDateGroup;

    if (
      !currentDateGroup ||
      currentDateGroup.month !== postPublishedAt.getMonth() + 1 ||
      currentDateGroup.year !== postPublishedAt.getFullYear()
    ) {
      currentDateGroup = {
        month: postPublishedAt.getMonth() + 1,
        year: postPublishedAt.getFullYear(),
        posts: [],
      };

      groups.push(currentDateGroup);
    }

    const postsGroup =
      currentDateGroup.posts[currentDateGroup.posts.length - 1];

    if (
      postCover?.type === 'image' &&
      prevPostCover?.type === 'image' &&
      postsGroup
    ) {
      postsGroup.push(post);
    } else {
      currentDateGroup.posts.push([post]);
    }

    prevDateGroup = currentDateGroup;
    prevPostCover = postCover;

    return groups;
  }, []);
}
