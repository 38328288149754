import { PostDto } from '../../../../shared/api';
import { clsx } from 'clsx';
import styles from './styles.module.scss';
import { ImageLink } from './image-link';

type Props = {
  posts: PostDto[];
  className?: string;
};

export function Grid(props: Props) {
  const { posts, className } = props;

  const cols = Array.from(
    new Array(posts.length > 4 ? 5 : posts.length).keys(),
  );
  const rows = Array.from(
    new Array(Math.ceil(posts.length / cols.length)).keys(),
  );

  const sizes: Record<number, string> = {
    1: '(max-width: 500px) 500px, 900px',
    2: '(max-width: 500px) 200px, 400px',
    3: '(max-width: 500px) 150px, 300px',
  };

  return (
    <div
      className={clsx(
        className,
        styles[`grid`],
        styles[`grid--cols-${cols.length}`],
      )}
    >
      {rows.map((row) => {
        return (
          <div key={row} className={styles['grid__row']}>
            {cols.map((col) => {
              const post = posts[row * cols.length + col];

              return (
                <div key={col} className={styles['grid__col']}>
                  {post && (
                    <ImageLink
                      sizes={
                        sizes[cols.length] || '(max-width: 500px) 200px, 400px'
                      }
                      className={styles['grid__item']}
                      post={post}
                    />
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
