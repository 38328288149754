import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { fixWordBreaks } from '../../../helpers';
import styles from './styles.module.scss';
import { truncate } from 'lodash';

type Props = {
  className?: string;
  name: string;
  description: string;
  image?: string;
  url: string;
};

export function Personality(props: Props) {
  const { className, url, name, description, image } = props;

  const fixedName = fixWordBreaks(name);
  const preparedDescription =
    description &&
    fixWordBreaks(
      truncate(description, {
        length: 240,
      }),
    );

  return (
    <div className={clsx(className, styles['personality'])}>
      <div className={styles['personality__content']}>
        <div className={styles['personality__name']}>{fixedName}</div>
        <div
          className={styles['personality__description']}
          dangerouslySetInnerHTML={{ __html: preparedDescription }}
        />
      </div>
      {image && (
        <div className={styles['personality__image-container']}>
          <Image
            className={styles['personality__image']}
            alt={name}
            fill={true}
            src={image}
            sizes={'(max-width: 499px) 500px, (max-width: 749px) 350px, 300px'}
          />
        </div>
      )}
      <Link className={styles['personality__link']} href={url} />
    </div>
  );
}
