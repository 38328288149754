import styles from './styles.module.scss';
import { useI18n } from '../../../i18n';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { getPublicationDateString } from './helpers';

export type Props = {
  date: Date;
  className?: string;
};

export function Meta(props: Props) {
  const { date, className } = props;

  const { i18n } = useI18n();

  const [publicationDate, setPublicationDate] = useState<string>(() => {
    const day = date.getUTCDate();
    const month = date.getUTCMonth();
    const monthName = i18n('months')[month]?.[1];
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const year = date.getUTCFullYear();
    const currentYear = new Date().getUTCFullYear();

    return getPublicationDateString({
      day,
      month: monthName || 'Unknown month',
      hours,
      minutes,
      year,
      currentYear,
    });
  });

  useEffect(() => {
    const day = date.getDate();
    const month = date.getMonth();
    const monthName = i18n('months')[month]?.[1];
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const year = date.getFullYear();
    const currentYear = new Date().getFullYear();

    return setPublicationDate(
      getPublicationDateString({
        day,
        month: monthName || 'Unknown month',
        hours,
        minutes,
        year,
        currentYear,
      }),
    );
  }, [date, i18n]);

  return (
    <div className={clsx(styles['meta'], className)}>{publicationDate}</div>
  );
}
