import { createPortal } from 'react-dom';
import styles from './styles.module.scss';
import { clsx } from 'clsx';
import Image from 'next/image';
import { FiLoader } from 'react-icons/fi';
import { useImageModal } from './use-image-modal';

type Props = {
  className?: string;
  width?: number;
  height?: number;
  src: string;
  onClose?: () => void;
};

export function ImageModal(props: Props) {
  const { className, onClose, src } = props;

  const { currentSrc, unoptimized, swipeable } = useImageModal({
    src,
    onClose,
  });

  return createPortal(
    <div {...swipeable} onClick={onClose} className={styles['modal']}>
      <FiLoader className={styles['modal__loader']} />
      <Image
        key={currentSrc}
        className={clsx(className, styles['modal__image'])}
        fill={true}
        alt={''}
        sizes={'100vw'}
        src={currentSrc}
        unoptimized={unoptimized}
      />
    </div>,
    document.body,
  );
}
